<template>
  <v-card flat>
    <v-card-subtitle> Roles </v-card-subtitle>
    <v-form ref="form" lazy-validation>
      <v-card-text class="d-flex">
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col cols="12" lg="6">
                <v-text-field
                  v-model="datos.nombre"
                  label="Nombre"
                  required
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field>

                <v-text-field
                  v-model="datos.descripcion"
                  label="Descripcion"
                  required
                  outlined
                  dense
                  hide-details="auto"
                  class="mt-4"
                ></v-text-field>
              </v-col>
              <v-col cols="12" lg="6">
                <v-select
                  dense
                  outlined
                  :items="itemsTipo"
                  label="Tipo"
                  item-value="id"
                  item-text="descripcion"
                  v-model="datos.indGlobal"
                  @change="CargarArbolModulo()"
                >
                </v-select>
              </v-col>
              <v-col cols="12">
                <h5>Permisos</h5>
                <v-divider></v-divider>
                <v-row class="mt-4">
                  <v-col cols="12" lg="6">
                    <v-treeview
                      style="max-height: 350px; overflow-y: auto"
                      item-text="nombre"
                      item-key="onlyId"
                      v-model="datos.accionModulo"
                      :items="items"
                      selection-type="leaf"
                      selectable
                      return-object
                      open-all
                    ></v-treeview>
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col class="pa-6" cols="12" lg="6">
                    <div style="max-height: 350px; overflow-y: auto">
                      <template v-if="!datos.accionModulo.length"> No hay permisos seleccionado </template>
                      <template v-else>
                        <div v-for="node in datos.accionModulo" :key="node.id">
                          {{ node.descripcion }}
                        </div>
                      </template>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="text-right">
            <v-btn color="secondary" class="me-3" @click="limpiar()" small> Limpiar </v-btn>
            <v-btn color="primary" class="me-3" small @click="Guardar()"> Guardar </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
  </v-card>
</template>
<script>
import { onBeforeMount, ref } from '@vue/composition-api'
import store from '@/store'
import goTo from 'vuetify/lib/services/goto'
import EmpleadoServices from '@/api/servicios/EmpleadoServices'

import SeguridadServices from '@/api/servicios/SeguridadServices'
export default {
  components: {},
  setup(props, context) {
    const guardando = ref(false)
    const form = ref(null)
    const selection = ref([])
    const items = ref([])

    const itemsTipo = ref([
      { id: 0, descripcion: 'Sucursal' },
      { id: 1, descripcion: 'Global' },
    ])
    let accionModuloModidicar = []
    const datosInit = {
      id: -1 /*int*/,
      nombre: '' /*string*/,
      descripcion: '',
      indActivo: true /*bool*/,
      accionModulo: [],
      indGlobal: 0,
    }
    onBeforeMount(() => {
      CargarArbolModulo()
      //items.value = store.state.user.arbol
    })
    const reglas = {
      requerido: [v => !!v || 'Nombre es requerido'],
    }
    const datos = ref(JSON.parse(JSON.stringify(datosInit)))

    const limpiar = () => {
      datos.value = JSON.parse(JSON.stringify(datosInit))
      accionModuloModidicar = []
      form.value.resetValidation()
    }

    const cargandos = ref(false)

    const CargarEditar = item => {
      datos.value = { ...item, indGlobal: item.indGlobal == true ? 1 : 0 }
      accionModuloModidicar = item.accionModulo
      CargarArbolModulo()
      goTo(0)
    }
    const validarForm = () => {
      let val = form.value?.validate()

      return val
    }
    const ActualizarLista = item => {
      console.log(item)
      context.emit('GetActualizarLista', { ...item })
    }
    const Guardar = () => {
      guardando.value = true
      if (validarForm()) {
        try {
          SeguridadServices.RolActualizar(store.state.user.idUsuario, {
            ...datos.value,
            indGlobal: datos.value.indGlobal == 1 ? true : false,
          })
            .then(response => {
              console.log(response)
              if (response.data.estatus == true) {
                store.commit('setAlert', {
                  message: response.data.mensaje,
                  type: 'success',
                })
                ActualizarLista(response.data.datos.datos)
                limpiar()
              } else {
                store.commit('setAlert', {
                  message: `${response.data.mensaje}: ` + response.data.datos.mensajeError,
                  type: 'warning',
                })
              }
            })
            .catch(error => {
              console.log(error)
              store.commit('setAlert', {
                message: error,
                type: 'error',
              })
            })
            .finally(() => {
              guardando.value = false
            })
        } catch (error) {
          //alert(error)
          store.commit('setAlert', {
            message: error,
            type: 'error',
          })
        }
      } else {
        store.commit('setAlert', {
          message: 'Verifique que todos los datos estén completos',
          type: 'warning',
        })
      }
    }

    const CargarArbolModulo = () => {
      
      SeguridadServices.ArbolModulo({ indGlobal: datos.value.indGlobal == 0 ? false : true , idEmpresa: store.state.user.idEmpresa})
        .then(response => {
          if (response.data.estatus == true) {
            items.value = response.data.datos
            if (accionModuloModidicar != []) {
              datos.value.accionModulo = JSON.parse(JSON.stringify(accionModuloModidicar))
            }
          } else {
            store.commit('setAlert', {
              message: `${response.data.mensaje}: `,
              type: 'warning',
            })
          }
        })
        .catch(err => {
          store.commit('setAlert', {
            message: `${err}: `,
            type: 'error',
          })
        })
      /*.finally(() => {

        })*/
    }

    return {
      reglas,
      datos,
      CargarEditar,
      cargandos,
      limpiar,
      Guardar,
      form,
      ActualizarLista,
      items,
      selection,
      itemsTipo,
      CargarArbolModulo,
    }
  },
}
</script>